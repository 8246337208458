export const Features = [
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Codeless.png',
    title: 'httpHeaderRewrite.section3.card1.title',
    content: 'httpHeaderRewrite.section3.card1.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_At-the-edge.png',
    title: 'httpHeaderRewrite.section3.card2.title',
    content: 'httpHeaderRewrite.section3.card2.content'
  },
  {
    image: 'https://www.mlytics.com/wp-content/uploads/2021/08/icon_Redirecting.png',
    title: 'httpHeaderRewrite.section3.card3.title',
    content: 'httpHeaderRewrite.section3.card3.content'
  }
]
